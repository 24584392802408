@import 'styles/includes.folkhem.scss';

.ArticleList {
    $root: &;

    &__Title {
        font-size: 2.4rem;
        font-weight: 300;
        line-height: 1.35;
        margin-bottom: 12px;
        color: rgba($colorBrandDark, 0.8);
    }

    &__List {
        @include media(md) {
            display: flex;
            flex-wrap: wrap;
            margin-left: -$gutter;
        }
    }

    &__Item {
        margin-bottom: $gutter;
        width: 100%;

        @include media(md) {
            margin-left: $gutter;
            width: calc(#{percentage(1 / 3)} - 24px);
        }
    }

    &__ItemImage {
        animation: fadeIn 1s ease forwards;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        display: block;
        height: 100%;
        position: relative;
        text-decoration: none;
        width: 100%;
        transition: transform 0.3s;

        #{$root}__LazyImgWrapper--Link:hover & {
            transform: scale(1.04);
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
            transition: background-color 0.3s;
            top: 0;
            left: 0;

            #{$root}__LazyImgWrapper--Link:hover & {
                background-color: rgba(0, 0, 0, 0.08);
            }
        }
    }

    &__LazyImgWrapper {
        background-color: $colorGray20;
        margin-bottom: 20px;
        overflow: hidden;
    }

    &__ItemImage,
    &__LazyImgWrapper {
        &::before {
            content: '';
            display: inline-block;
            height: 0;
            padding-bottom: calc(100% / (371 / 248));
        }

        &.is-visible::before {
            display: none;
        }
    }

    &__ItemTitle {
        font-size: 2rem;
        font-weight: 300;
        line-height: 1.35;
        margin-bottom: 5px;
        color: rgba($colorBrandDark, 0.8);

        @include media(lg) {
            font-size: 2rem;
        }
    }

    &__ItemText {
        font-size: 1.4rem;
        margin-bottom: 27px;
        line-height: (20/14);
        color: rgba($colorBlack, 0.6);
        font-weight: 300;
    }

    &__ItemLink {
        font-weight: 400;
        text-decoration: none;
        color: $colorBrand;
        font-size: 1.6rem;
        letter-spacing: 1.3px;

        &:hover {
            color: $colorBrand;
            opacity: 0.8;
        }
    }
}
