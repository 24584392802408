@import 'styles/includes.nordr.scss';

.ArticleList {
    $root: &;

    &__Title {
        font-weight: 500;
        margin-bottom: 24px;
        font-size: 2.2rem;
        line-height: 1.33;
        letter-spacing: 0.8px;

        @include media(md) {
            font-size: 2.4rem;
        }
    }

    &__List {
        @include media(md) {
            display: flex;
            flex-wrap: wrap;
            margin-left: -24px;
        }
    }

    &__Item {
        margin-bottom: 24px;
        width: 100%;

        @include media(md) {
            margin-bottom: 48px;
            margin-left: 24px;
            width: calc(#{percentage(1 / 3)} - 24px);
        }
    }

    &__ItemImage {
        animation: fadeIn 1s ease forwards;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        display: block;
        height: 100%;
        position: relative;
        text-decoration: none;
        width: 100%;
        will-change: transform;
        transition: transform 0.3s;

        #{$root}__LazyImgWrapper--Link:hover & {
            transform: scale(1.04);
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
            transition: background-color 0.3s;
            top: 0;
            left: 0;

            #{$root}__LazyImgWrapper--Link:hover & {
                background-color: rgba(0, 0, 0, 0.08);
            }
        }
    }

    &__LazyImgWrapper {
        background-color: $colorBrownLight40;
        margin-bottom: 20px;
        overflow: hidden;
    }

    &__ItemImage,
    &__LazyImgWrapper {
        &::before {
            content: '';
            display: inline-block;
            height: 0;
            padding-bottom: calc(100% / (371 / 248));
        }

        // &.is-visible::before {
        //     display: none;
        // }
    }

    &__ItemTitle {
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 1.35;
        letter-spacing: 0.8px;
        margin-bottom: 16px;

        @include media(lg) {
            font-size: 2.4rem;
        }
    }

    &__ItemText {
        line-height: 1.75;
        letter-spacing: 0.2px;
        margin-bottom: 16px;

        @include media(lg) {
            margin-bottom: 32px;
        }
    }

    &__ItemLink {
        font-weight: 500;
        letter-spacing: 1px;
        text-decoration: none;
        font-size: 1.5rem;
        line-height: 1.5;
        color: $colorGray100;
        display: flex;
        align-items: center;

        &:after {
            content: '';
            background-image: url('#{$basepath}svg/icon-arrow-right-nordr.svg');
            background-position: bottom left;
            background-repeat: no-repeat;
            background-size: 20px;
            padding-top: 2px;
            width: 20px;
            height: 20px;
            display: inline-block;
            margin: 0 0 0px 14px;
            transition: margin 0.15s ease;
        }

        &:hover {
            opacity: 0.8;

            &:after {
                margin: 0 0 0px 20px;
            }
        }
    }
}
